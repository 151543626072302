import ContactForm from "components/ContactForm";
import Page from "components/Page";
import { graphql } from "gatsby";
import React from "react";
import Markdown from "react-markdown";
import { PageLocale } from "types";
import Wrapper from "components/Wrapper";
import {
  Hero,
  HeroImageContainer,
  HeroImg,
  HeroMessageContainer,
  HeroSubtitle,
  HeroTitle,
  Separator,
  Section,
  SectionDescription,
  SectionInfo
} from "components/UI";
import styled from "styled-components";
import Icon from "components/Icon";

interface Props {
  data: {
    page: {
      fields: {
        name: string;
        locales: {
          language: string;
          path: string;
          title: string;
          seo: {
            description: string;
            image: string;
          };
          why: {
            title: string;
            body: string;
          };
          contacts: {
            title: string;
            body: string;
          };
          hero: {
            image: string;
            title: string;
            subtitle: string;
          };
        }[];
      };
    };
  };
  pageContext: {
    name: string;
    locale: string;
  };
}

const LandingPage = ({ data, pageContext }: Props) => {
  const currentPage = data.page.fields.locales.find(
    locale => locale.language === pageContext.locale
  )!;

  return (
    <Page
      title={currentPage.title}
      description={currentPage.seo.description}
      localeCode={pageContext.locale}
      image={currentPage.seo.image}
      pageLocales={data.page.fields.locales.map(
        (locale): PageLocale => ({ code: locale.language, url: locale.path })
      )}
      hideHeader
    >
      <Container>
        <Hero small>
          <HeroImageContainer>
            <HeroFilter />
            <HeroImg src={currentPage.hero.image} />
          </HeroImageContainer>
          <Wrapper>
            <HeroMessageContainer>
              <Icon />
              <HeroTitle
                dangerouslySetInnerHTML={{ __html: currentPage.hero.title }}
              />
              <Separator light />
              <HeroSubtitle>{currentPage.hero.subtitle}</HeroSubtitle>
            </HeroMessageContainer>
          </Wrapper>
        </Hero>

        <Section>
          <Wrapper>
            <SectionInfo>{currentPage.why.title}</SectionInfo>
            <Separator />
            <SectionDescription>
              <Markdown source={currentPage.why.body} />
            </SectionDescription>
          </Wrapper>
        </Section>
        <Section>
          <Wrapper>
            <SectionInfo>{currentPage.contacts.title}</SectionInfo>

            <FormContainer>
              <ContactForm pageName="Landing" />
            </FormContainer>
            <SectionDescription>
              <Markdown source={currentPage.contacts.body} />
            </SectionDescription>
          </Wrapper>
        </Section>
      </Container>
    </Page>
  );
};

export const query = graphql`
  query LandingPageQuery($name: String!) {
    page: pagesJson(fields: { name: { eq: $name } }) {
      fields {
        name
        locales {
          language
          path
          title
          seo {
            description
            image
          }
          hero {
            title
            subtitle
            image
          }
          why {
            title
            body
          }
          contacts {
            title
            body
          }
        }
      }
    }
  }
`;

export default LandingPage;

const Container = styled.div`
  ${Icon} {
    fill: ${props => props.theme.colors.white};
    width: 80px;
    display: block;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  ${HeroMessageContainer} {
    padding-top: 0;
    text-align: center;
    width: 100%;
  }
  ${HeroTitle} {
    width: 100%;
    max-width: 100%;
  }
  ${HeroSubtitle} {
    font-size: 16px;
  }
  ${Separator} {
    margin: 25px auto 20px auto;
  }
  ${Section} {
    padding: 0;
    margin: 80px 0;
  }
  ${SectionInfo} {
    width: 100%;
    max-width: 100%;
    text-align: center;
  }
  ${SectionDescription} {
    font-size: 16px;
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    p {
      margin-bottom: 20px;
    }
    ul {
      list-style: none;
    }
    li {
      background-color: rgba(215, 238, 236, 0.4);
      margin-bottom: 15px;
      padding: 4px 15px 4px 55px;
      position: relative;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      justify-content: center;
      &::before {
        content: "•";
        display: block;
        position: absolute;
        display: flex;
        align-items: center;

        justify-content: center;
        top: 0;
        left: 0;
        height: 100%;
        width: 40px;
        background-color: rgb(215, 238, 236);
        font-size: 22px;
        color: rgba(6, 85, 79, 0.8);
        font-family: monospace;
      }
    }
  }
`;

const HeroFilter = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(6, 85, 79, 0.8);
`;

const FormContainer = styled.div`
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 80px;
`;
